html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #343a40;
  color: rgb(255,255,255);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Styling for the main navbar */
.navbar {
  margin-bottom: 25px;
  border-bottom: 1px solid rgb(255,255,255);
}

/* Styling for the rectangle objects */
.sort-page-container{
  width: 100%;
  margin-left:0;
}

.sort-page-container .sort-name{
  font-size: 1.5em;
  text-decoration-style: double;
  margin-bottom: 0;
}

.sort-page-container .sort-definition {
  width: 95%;
  background-color: rgb(194, 194, 194);
  color: rgb(107, 18, 119);
  white-space: pre-wrap;
  font-size: 0.75em;
  display: block;
  max-height: 150px;
  overflow-y: scroll;
}

.sort-controls {
  margin-top: 25px;
  margin-bottom: 25px;
}
.sort-controls .row {
  margin-bottom: 15px;
}
.sort-controls input,
.sort-controls button {
  margin-left: 5px;
  margin-right: 5px;
}
.sort-controls input[type="text"] {
  width: 3em;
}
.sort-controls label {
  margin-bottom: 0;
}


.rectangle-container {
  padding-left: 15px;
  width: 100%;
}

.rectangle-container .col {
  padding-right: 0;
  padding-left: 0;
}

.single-rectangle-container {
  height: 11em;
  max-width: 2.5em;
  padding: 0.25em;
  display: flex;
  flex-direction: column-reverse;
}

.rectangle {
  width: 100%;
  background-color: rgb(204, 93, 14);
}

/* Defines colors of the bars for various stages (current, inner, sorted, etc) */
.rectangle.sort.current {
  background-color: rgb(0, 255, 42);
}
.rectangle.sort.current2 {
  background-color: rgb(255, 230, 0);
}
.rectangle.sort.pivot {
  background-color: rgb(255, 0, 0);
}
.rectangle.sort.sorted {
  background-color: rgb(72, 203, 255)
}
  

.rectangle-label-below {
  width: 100%;
  padding-top: 0.5em;
  color: rgb(255,255,255);
}